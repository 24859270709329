import React from "react";

import { Button, Form } from "reactstrap";

import { BEM } from "helpers/BEM.helper";
import { PasswordValidationMessages } from "views/components/forms/PasswordValidationMessages";
import { assertPasswordIsValid } from "helpers/inputValidators/text/password";
import { FetchFormErrors } from "types/FetchFormErrors";
import { Field, Formik } from "formik";
import { FetchInputFormik } from "views/common/forms/fields/FetchInputFormik";

const classes = new BEM({
  block: { name: "ChangePasswordForm" },
  elements: [
    { name: "oldPassword", extras: [] },
    { name: "newPassword", extras: [] },
    { name: "confirmNewPassword", extras: [] },
    { name: "submitButton", extras: ["w-100"] },
  ],
});

export type ChangePasswordFormProps = {
  onSubmit: (values: IChangePasswordFormValues) => void;
};

export interface IChangePasswordFormValues {
  oldPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
}
type ChangePasswordFormErrors = FetchFormErrors<IChangePasswordFormValues>;

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = (
  props: ChangePasswordFormProps,
) => {
  return (
    <Formik
      validateOnMount={true}
      initialValues={{
        confirmNewPassword: "",
        newPassword: "",
        oldPassword: "",
      }}
      onSubmit={props.onSubmit}
      validate={(values: IChangePasswordFormValues) => {
        const errors: ChangePasswordFormErrors = {};
        const requiredFields: (keyof IChangePasswordFormValues)[] = [
          "oldPassword",
          "newPassword",
          "confirmNewPassword",
        ];
        requiredFields.forEach((fieldName: keyof IChangePasswordFormValues) => {
          if (!values[fieldName]) {
            errors[fieldName] = "Required";
          }
        });
        if (!assertPasswordIsValid(values.newPassword)) {
          errors.newPassword = "Enter valid password";
        }
        if (values.newPassword !== values.confirmNewPassword) {
          errors.confirmNewPassword = "Passwords must match";
        }
        return errors;
      }}
    >
      {(formProps) => {
        return (
          <Form
            className={classes.getBlockClassNames()}
            onSubmit={formProps.handleSubmit}
          >
            <Field
              className={classes.getElementClassNames("oldPassword")}
              component={FetchInputFormik}
              label="Old Password"
              name="oldPassword"
              type="password"
            />
            <Field
              className={classes.getElementClassNames("newPassword")}
              component={FetchInputFormik}
              label="New Password"
              name="newPassword"
              type="password"
            />
            <Field
              className={classes.getElementClassNames("confirmNewPassword")}
              component={FetchInputFormik}
              label="Confirm New Password"
              name="confirmNewPassword"
              type="password"
            />
            <PasswordValidationMessages
              password={formProps.values.newPassword}
            />
            <Button
              type="submit"
              color="info"
              disabled={!formProps.isValid}
              className={classes.getElementClassNames("submitButton")}
            >
              Submit
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
