import React from "react";

import {
  Collapse,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
} from "reactstrap";

import { BEM } from "helpers/BEM.helper";
import { FetchIcon } from "views/common/misc/FetchIcon";
import { IconTypes } from "constants/icons";
import { SearchField } from "views/components/navbars/SearchField";
import { Field, Formik } from "formik";
import { useDispatch } from "react-redux";
import { quickSearchActions } from "slices/quickSearch";

const getSearchPlaceholder = (type?: SearchTypes): string => {
  switch (type) {
    case "package":
      return "Package Id";
    case "batch":
      return "Batch Id";
    case "user":
      return "User Code";
    default:
      return "Select a search type";
  }
};

const getSearchIcon = (type?: SearchTypes): IconTypes => {
  switch (type) {
    case "package":
      return "package";
    case "batch":
      return "edit";
    case "user":
      return "user";
    default:
      return "search";
  }
};

export interface QuickSearchFormProps {
  isOpen: boolean;
}
type QuickSearchFormValuesIndex = "searchValue" | "resourceType";
export interface IQuickSearchFormValues {
  searchValue?: string;
  resourceType?: SearchTypes;
}
interface IQuickSearchFormErrors {
  searchValue?: string;
  resourceType?: string;
}
type SearchTypes = "package" | "user" | "batch";

const classes = new BEM({
  block: {
    name: "QuickSearchForm",
    extras: ["menu-search", "justify-content-end"],
  },
  elements: [
    { name: "categorySelect", extras: [] },
    { name: "categoryOption", extras: [] },
  ],
});
const menuOptions: {
  icon: IconTypes;
  text: string;
  type: SearchTypes;
}[] = [
  { icon: "user", text: "User", type: "user" },
  { icon: "package", text: "Package", type: "package" },
  { icon: "edit", text: "Batch", type: "batch" },
];

export const QuickSearchForm: React.FC<QuickSearchFormProps> = (
  props: QuickSearchFormProps,
) => {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        resourceType: "package",
        searchValue: "",
      }}
      onSubmit={({ resourceType, searchValue }: IQuickSearchFormValues) => {
        if (!!resourceType && !!searchValue) {
          dispatch(
            quickSearchActions.performSearch({
              resourceType,
              searchValue,
            }),
          );
        }
      }}
      validate={(values: IQuickSearchFormValues) => {
        const errors: IQuickSearchFormErrors = {};
        const requiredFields: QuickSearchFormValuesIndex[] = ["searchValue"];

        requiredFields.forEach((fieldName: QuickSearchFormValuesIndex) => {
          if (!values[fieldName]) {
            errors[fieldName] = "Required";
          }
        });

        return errors;
      }}
    >
      {(formProps) => {
        return (
          <Collapse
            className={classes.getBlockClassNames()}
            navbar={true}
            isOpen={props.isOpen}
          >
            <Nav navbar={true}>
              <UncontrolledDropdown className="btn-magnify" nav={true}>
                <DropdownToggle
                  aria-haspopup={true}
                  caret={true}
                  color="default"
                  data-toggle="dropdown"
                  nav={true}
                >
                  <FetchIcon
                    iconType={getSearchIcon(formProps.values.resourceType)}
                    className={classes.getElementClassNames("categorySelect")}
                  />
                </DropdownToggle>
                <DropdownMenu
                  aria-labelledby="navbarDropdownMenuLink"
                  right={true}
                >
                  {menuOptions.map(({ icon, text, type }) => (
                    <DropdownItem
                      key={icon}
                      onClick={() => {
                        void formProps.setFieldValue("resourceType", type);
                      }}
                      className={classes.getElementClassNames("categoryOption")}
                    >
                      <FetchIcon iconType={icon} /> {text}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Form
              className={classes.getBlockClassNames()}
              onSubmit={formProps.handleSubmit}
            >
              <Field
                component={SearchField}
                name="searchValue"
                type="text"
                placeholder={getSearchPlaceholder(
                  formProps.values.resourceType,
                )}
                submitHandler={formProps.handleSubmit}
              />
              <Field type="hidden" name="resourceType" component="input" />
            </Form>
          </Collapse>
        );
      }}
    </Formik>
  );
};

export default QuickSearchForm;
