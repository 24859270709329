import React from "react";

import { Button, Form } from "reactstrap";

import { BEM } from "helpers/BEM.helper";
import { PasswordValidationMessages } from "views/components/forms/PasswordValidationMessages";
import { assertPasswordIsValid } from "helpers/inputValidators/text/password";
import { FetchFormErrors } from "types/FetchFormErrors";
import { Field, Formik } from "formik";
import { FetchInputFormik } from "views/common/forms/fields/FetchInputFormik";

const classes = new BEM({
  block: { name: "ConfirmResetPasswordForm" },
  elements: [
    { name: "temporaryPassword", extras: [] },
    { name: "confirmTemporaryPassword", extras: [] },
    { name: "invalidPasswordAlert", extras: [] },
    { name: "confirmButton", extras: ["w-100"] },
  ],
});

export interface ConfirmResetPasswordFormProps {
  onSubmit: (values: IConfirmResetPasswordFormValues) => void;
}

export type IConfirmResetPasswordFormValues = {
  temporaryPassword: string;
  confirmTemporaryPassword: string;
};

type IConfirmResetPasswordFormErrors =
  FetchFormErrors<IConfirmResetPasswordFormValues>;

export const ConfirmResetPasswordForm: React.FC<
  ConfirmResetPasswordFormProps
> = (props: ConfirmResetPasswordFormProps) => {
  return (
    <Formik
      validateOnMount={true}
      initialValues={{
        confirmTemporaryPassword: "",
        temporaryPassword: "",
      }}
      onSubmit={props.onSubmit}
      validate={(
        values: IConfirmResetPasswordFormValues,
      ): IConfirmResetPasswordFormErrors => {
        const errors: IConfirmResetPasswordFormErrors = {};
        const requiredFields: (keyof IConfirmResetPasswordFormValues)[] = [
          "temporaryPassword",
          "confirmTemporaryPassword",
        ];
        requiredFields.forEach((fieldName) => {
          if (!values[fieldName]) {
            errors[fieldName] = "Required";
          }
        });
        if (!assertPasswordIsValid(values.temporaryPassword)) {
          errors.temporaryPassword = "Enter valid password";
        }
        if (values.temporaryPassword !== values.confirmTemporaryPassword) {
          errors.confirmTemporaryPassword = "Passwords must match";
        }
        return errors;
      }}
    >
      {(formProps) => {
        return (
          <Form
            className={classes.getBlockClassNames()}
            onSubmit={formProps.handleSubmit}
          >
            <Field
              className={classes.getElementClassNames("temporaryPassword")}
              component={FetchInputFormik}
              label="Temporary Password"
              name="temporaryPassword"
              type="password"
            />
            <Field
              className={classes.getElementClassNames(
                "confirmTemporaryPassword",
              )}
              component={FetchInputFormik}
              label="Confirm Temporary Password"
              name="confirmTemporaryPassword"
              type="password"
            />
            <PasswordValidationMessages
              password={formProps.values.temporaryPassword}
            />
            <Button
              type="submit"
              color="info"
              className={classes.getElementClassNames("confirmButton")}
              disabled={!formProps.isValid}
            >
              Confirm
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ConfirmResetPasswordForm;
