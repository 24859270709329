import React from "react";

import { BEM } from "helpers/BEM.helper";
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputProps,
} from "reactstrap";
import { FetchIcon } from "views/common/misc/FetchIcon";

const classes = new BEM({
  block: { name: "SearchField", extras: ["no-border"] },
  elements: [{ name: "button", extras: ["btn-magnify"] }, { name: "input" }],
});

export interface SearchFieldProps extends InputProps {
  submitHandler: () => void;
}

export const SearchField: React.FC<SearchFieldProps> = (
  props: SearchFieldProps,
) => {
  return (
    <InputGroup className={classes.getBlockClassNames()}>
      <Input
        {...props.field}
        placeholder={props.placeholder}
        className={classes.getElementClassNames("input")}
      />
      <InputGroupAddon addonType="append">
        <InputGroupText>
          <div
            onClick={props.submitHandler}
            className={classes.getElementClassNames("button")}
          >
            <FetchIcon iconType="search" />
          </div>
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};
