import React, { ReactNode, KeyboardEvent } from "react";

import { Input, InputGroup, InputGroupAddon, InputProps } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";

import {
  generateBemClassNames,
  ClassNameCalculator,
} from "helpers/generateBemClassNames.helper";
import { enumFormatter } from "helpers/text-formatting/enumFormatter";
import { useFetchSelector } from "helpers/hooks/useFetchSelector.hook";
import { ApplicationSettingsLocationIndex } from "types/IRootStateType";
import {
  FetchFieldValidationWrapperFormik,
  FetchFieldValidationWrapperFormikProps,
} from "./FetchFieldValidationWrapperFormik";
import { FieldProps } from "formik";

export type FetchInputFormikType = InputType & "displayAsText" & "inputGroup";

export type FetchInputFormikProps = FetchFieldValidationWrapperFormikProps &
  InputProps & {
    appendComponent?: ReactNode;
    placeholder?: string;
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
    displayAsText?: boolean;
    inputGroup?: boolean;
    type: FetchInputFormikType;
    enumType?: ApplicationSettingsLocationIndex;
    disabled?: boolean;
    containerClasses?: string;
  } & FieldProps;

export const FetchInputFormik: React.FC<FetchInputFormikProps> = (
  props: FetchInputFormikProps,
) => {
  const {
    appendComponent,
    type,
    innerRef,
    placeholder,
    className,
    onKeyDown,
    enumType,
  } = props;
  let inputField;
  const applicationSettings = useFetchSelector("applicationSettings");
  const fieldValue = props.form.values[props.field.name];

  const classNames = generateBemClassNames({
    block: { name: "input" },
    elements: [{ name: type }, { name: "error" }, { name: "label" }],
    prefix: "fetch",
  });
  const {
    block,
    elements: { error, label: labelClassNames },
  } = classNames;
  const typeClassNames: ClassNameCalculator = classNames.elements[type];

  if (type === "displayAsText") {
    const displayValue = fieldValue
      ? !!enumType
        ? enumFormatter.firstLetterCapitalizedWithSpaces(
            applicationSettings?.[enumType]?.[fieldValue],
          )
        : `${fieldValue}`
      : "N/A";
    inputField = (
      <div
        className={typeClassNames({ extras: ["form-field-display-as-text"] })}
      >
        {displayValue}
      </div>
    );
  } else if (type === "inputGroup") {
    inputField = (
      <InputGroup>
        <Input
          {...props.field}
          disabled={props.disabled}
          type="text"
          label={props.label}
          autoComplete="off"
          innerRef={innerRef}
          placeholder={placeholder}
          className={typeClassNames()}
          onKeyDown={onKeyDown}
        />
        {appendComponent && (
          <InputGroupAddon addonType="append">
            {appendComponent}
          </InputGroupAddon>
        )}
      </InputGroup>
    );
  } else {
    inputField = (
      <Input
        {...props.field}
        disabled={props.disabled}
        type={type}
        label={props.label}
        autoComplete="off"
        innerRef={innerRef}
        placeholder={placeholder}
        className={typeClassNames()}
        onKeyDown={onKeyDown}
      />
    );
  }

  return (
    <div data-cy={"FetchInputFormik"} className={props.containerClasses}>
      <FetchFieldValidationWrapperFormik
        {...{
          ...props,
          type,
          classNames: {
            block: block({ extras: [className ? className : ""] }),
            label: labelClassNames(),
            error: error(),
          },
        }}
      >
        {inputField}
      </FetchFieldValidationWrapperFormik>
    </div>
  );
};
