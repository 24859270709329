import { IconTypes } from "constants/icons";
import { getDefaultColumnsQueryParameters } from "helpers/getDefaultColumnsQueryParameters.helper";
import { FeatureFlags } from "constants/feature-flags";
import { UserPermissions } from "types/UserPermissions";
import { UserPrivileges, userPrivileges } from "types/UserPrivileges";

// each path & name must be unique
export interface NavRoute {
  path: string;
  name: string;
  icon?: IconTypes;
  permissions: UserPermissions;
  featureFlags?: FeatureFlags[];
  privileges?: UserPrivileges[];
  children?: Array<Omit<NavRoute, "children">>;
  inErrorState?: boolean;
}
export const navRoutePathConstants = {
  market: {
    home: "/market",
    orderSuccessCTA: "/market/checkout/success",
    cart: "/market/cart",
  },
  internal: {
    featureFlagsTable: "/feature-flags",
    featureFlagForm: "/feature-flags/:featureFlagName",
    createFeatureFlag: "/feature-flag",
  },
  wfm: {
    recurringSchedule: "/wfm/recurringSchedule",
    blockSchedule: "/wfm/blockSchedule",
    approvals: "/wfm/approvals",
    payments: "/wfm/payments/history",
    windowDetail: "/wfm/window-detail",
  },
};

export const navRoutes: NavRoute[] = [
  {
    icon: "package",
    name: "My Packages",
    path: "/myPackages",
    permissions: {
      residentialUser: true,
    },
    children: [
      {
        name: "Package History",
        path: "/myPackageHistory",
        permissions: {
          residentialUser: true,
        },
      },
      {
        name: "How Does Fetch Work?",
        path: "/howDoesFetchWork",
        permissions: {
          residentialUser: true,
        },
      },
    ],
  },
  {
    icon: "faShoppingBag",
    name: "Market",
    path: navRoutePathConstants.market.home,
    permissions: {
      residentialUser: true,
    },
    featureFlags: [FeatureFlags["res-store-mvp-web"]],
    children: [],
  },
  {
    icon: "bone",
    name: "Treats",
    path: "/treats",
    permissions: {
      residentialUser: true,
    },
  },
  {
    icon: "box",
    name: "Storage",
    path: "/storage",
    permissions: {
      residentialUser: true,
    },
    featureFlags: [FeatureFlags["storage-by-building-v2"]],
  },
  {
    icon: "package",
    name: "Packages",
    path: "/packages",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    children: [
      {
        name: "Add Package",
        path: "/package",
        permissions: {
          adminUser: true,
          warehouseUser: true,
        },
        featureFlags: [FeatureFlags["enable-manual-logging"]],
      },
      {
        name: "Logging X - Queue",
        path: "/logging",
        permissions: {
          adminUser: true,
          warehouseUser: true,
        },
      },
      {
        name: "Logging X - Bulk Label Print",
        path: "/bulk-label-print",
        permissions: {
          adminUser: true,
          warehouseUser: true,
        },
      },
      {
        name: "Logging X - Logging Dashboard",
        path: "/logging-dashboard",

        permissions: {
          adminUser: true,
          warehouseUser: true,
        },
        privileges: [UserPrivileges.FETCHPACKAGES_LABELS_ANALYZE_SEARCH_GET],
      },
      {
        name: "Full Text Search",
        path: "/full-text-search",

        permissions: {
          adminUser: true,
          warehouseUser: true,
        },
        privileges: [UserPrivileges.FETCHPACKAGES_LABELS_FULLTEXTSEARCH_GET],
      },
    ],
  },
  {
    icon: "user",
    name: "Users",
    path: "/users",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    children: [
      {
        name: "Add User",
        path: "/user",
        permissions: {
          adminUser: true,
          warehouseUser: true,
        },
      },
    ],
  },
  {
    icon: "building",
    name: "Buildings",
    path: "/buildings",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    privileges: [...userPrivileges.buildings.search],
  },
  {
    icon: "warehouse",
    name: "Warehouses",
    path: "/warehouses",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    children: [
      {
        name: "Pickups",
        path:
          "/pickups?status=10" +
          getDefaultColumnsQueryParameters([
            "fetchPackageId",
            "createdDate",
            "userName",
            "userCode",
            "locationCode",
            "status",
          ]),
        permissions: {
          adminUser: true,
          warehouseUser: true,
        },
      },
    ],
  },
  {
    icon: "warehouseLocation",
    name: "Locations",
    path: "/warehouse/locations",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    children: [
      {
        name: "Manage Locations",
        path: "/warehouse/batchLocations",
        permissions: {
          adminUser: true,
          warehouseUser: true,
        },
      },
    ],
  },
  {
    icon: "loadingTruck",
    name: "My Deliveries",
    path: "/myDeliveries",
    permissions: {
      driverUser: true,
    },
  },
  {
    icon: "batches",
    name: "Batches",
    path: "/batches",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    children: [
      {
        name: "Add Batch",
        path: "/batch",
        permissions: {
          adminUser: true,
          warehouseUser: true,
        },
      },
      {
        name: "Today Detail",
        path: "/todayDetail",
        permissions: {
          warehouseUser: true,
        },
      },
      {
        name: "Batch Progress",
        path: "/batchProgress",
        permissions: {
          warehouseUser: true,
        },
      },
      {
        name: "Batch Audit Progress",
        path: "/batchAuditProgress",
        permissions: {
          warehouseUser: true,
        },
      },
      {
        name: "Super Router",
        path: "/superRouter",
        permissions: {
          adminUser: true,
          warehouseUser: true,
        },
        privileges: [UserPrivileges._SUPER_ROUTER_ACCESS],
      },
    ],
  },
  {
    icon: "loadingTruck",
    name: "Workforce",
    path: navRoutePathConstants.wfm.blockSchedule,
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    privileges: [
      ...userPrivileges.blockSchedule,
      ...userPrivileges.approvals,
      ...userPrivileges.windowDetail,
    ],
    children: [
      {
        icon: "faCalendarClock",
        name: "Recurring Schedule",
        path: navRoutePathConstants.wfm.recurringSchedule,
        permissions: {
          adminUser: true,
          warehouseUser: true,
        },

        privileges: userPrivileges.recurringSchedule,
      },
      {
        icon: "faUserClock",
        name: "Block Schedule",
        path: navRoutePathConstants.wfm.blockSchedule,
        permissions: {
          adminUser: true,
          warehouseUser: true,
        },

        privileges: userPrivileges.blockSchedule,
      },
      {
        icon: "faMoneyCheckDollarPen",
        name: "Block Approvals",
        path: navRoutePathConstants.wfm.approvals,
        permissions: {
          adminUser: true,
          warehouseUser: true,
        },

        privileges: userPrivileges.approvals,
      },
      {
        icon: "faMoneyBillWave",
        name: "Payments History",
        path: navRoutePathConstants.wfm.payments,
        permissions: {
          adminUser: true,
          warehouseUser: true,
        },

        privileges: userPrivileges.payments,
      },
    ],
  },
  {
    icon: "gearComplexCode",
    name: "Application Configuration",
    path: navRoutePathConstants.internal.featureFlagsTable,
    permissions: {
      adminUser: true,
    },
    featureFlags: [],
    privileges: [...userPrivileges.featureFlags.all],
    children: [
      {
        icon: "userEdit",
        name: "Create Feature Flag",
        path: navRoutePathConstants.internal.createFeatureFlag,
        permissions: {
          adminUser: true,
        },
        featureFlags: [],
        privileges: userPrivileges.featureFlags.create,
      },
    ],
  },
];
