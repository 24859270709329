import React from "react";

import { Dispatch } from "redux";

import { authenticationActions } from "slices/authentication/authentication.actions";
import { BEM } from "helpers/BEM.helper";
import { FetchConnect } from "helpers/FetchConnect";
import FetchModal from "views/common/modals/FetchModal";
import UserAttributeVerificationForm from "views/components/forms/UserAttributeVerificationForm";
import { IRootStateType } from "types/IRootStateType";

export interface VerifyUserPhoneFormModalProps extends IRootStateType {
  dispatch: Dispatch;
}

export const VerifyUserPhoneFormModal: React.FC<
  VerifyUserPhoneFormModalProps
> = ({
  dispatch,
  authentication: { tokens },
}: VerifyUserPhoneFormModalProps) => {
  const classes = new BEM({
    block: { name: "modals" },
    elements: [{ name: "verifyPhone" }],
    prefix: { name: "fetch" },
  });

  return (
    <FetchModal
      title="Verify Phone Number"
      className={classes.getElementClassNames("verifyPhone")}
      id="verify_phone"
    >
      <div className="user-attribute-modal-text-padding">
        A verification code was sent to your phone number. Please enter it
        below.
      </div>
      <UserAttributeVerificationForm
        {...{
          onSubmit: (values) => {
            if (tokens && tokens.userToken) {
              dispatch(
                authenticationActions.verifyUserAttribute({
                  attributeName: "phone_number",
                  code: values.code,
                  userToken: tokens.userToken,
                }),
              );
            }
          },
        }}
      />
    </FetchModal>
  );
};

export default FetchConnect({
  ConnectedComponent: VerifyUserPhoneFormModal,
  state: { stores: ["authentication", "ui"] },
});
