export enum WarehouseLocationTypes {
  "WINDOW" = 5,
  "WINDOW_TRANSITION" = 6,
  "UNSCHEDULED" = 10,
  "OVERFLOW" = 15,
  "UNIVERSAL" = 20,
  "HOLD" = 25,
}

export enum WfmBlockStates {
  "AVAILABLE" = 1,
  "CLAIMED" = 2,
  "CANCELED" = 4,
  "UNCLAIMED" = 5,
  "COMPLETED" = 6,
  "OVERFILLED" = 7,
  "ABANDONED" = 8,
  "INCOMPLETED" = 9,
  "VALIDATION_HOLD" = 10,
}

export enum UploadFileTypes {
  "DELIVERY_RECEIPT" = 1,
  "PROFILE_IMG" = 2,
  "BUILDING_UNIT_MAP" = 3,
  "LABEL_IMAGE" = 4,
  "COURIER_LOG" = 5,
}

export enum DriverBatchAuditStatusOptions {
  AUDIT_BLOCKED = "AUDIT_BLOCKED",
  AUDIT_PROCEED = "AUDIT_PROCEED",
  AUDIT_COMPLETE = "AUDIT_COMPLETE",
}

export enum WfmBlockCancellationReasonCodes {
  "Low volume cancellation" = 1,
  "Driver called in" = 2,
  "Created in error" = 3,
  "Driver Deactivated" = 4,
  "Driver Unresponsive" = 5,
}

export enum BatchAuditStatusOptions {
  "UNAUDITED" = 5,
  "IN_PROGRESS" = 10,
  "COMPLETED" = 15,
  "DRIVER_AUDIT_IN_PROGRESS" = 20,
  "DRIVER_AUDIT_COMPLETED" = 25,
}

export enum DeliveryMethods {
  "LEAVE" = 1,
  "DO_NOT_LEAVE" = 2,
  "SPECIAL_DELIVERY" = 3,
  "PICKUP" = 6,
}

export enum PackageStatusOptions {
  "PENDING_RECEIVE" = 1,
  "RECEIVED" = 2,
  "DELIVERY_SCHEDULED" = 3,
  "OUT_FOR_DELIVERY" = 4,
  "DELIVERED" = 5,
  "EXCEPTION_TRIED_ONCE" = 6,
  "EXCEPTION_TRIED_TWICE" = 7,
  "PICKUP_REQUESTED" = 10,
  "BATCHED" = 11,
}

export enum AutoScheduleDeliveryOptions {
  "disabled" = 0,
  "nextAvailable" = -1,
  "invalidOption" = -2,
}

export enum WfmPayEntrySourcesUploadAllowed {
  "Adjustment-Bonus" = 4,
  "Adjustment-Extra" = 5,
  "Adjustment-Preferred" = 6,
  "Adjustment-Tier" = 7,
}

export enum PackageAuditTypes {
  "LOCATION" = 5,
  "WAREHOUSE" = 15,
  "DRIVER" = 20,
}

export enum FetchPackageTypes {
  "JIFFY" = 5,
  "STANDARD" = 10,
  "LARGE" = 15,
  "OVERSIZE" = 20,
  "FETCH MARKET" = 25,
}

export const StripePublicKey =
  "pk_test_51Ke4ZDDf9oj7B2s9ZcSOJLQgYbOeySIj8sNoMzntlmyIqAgXZWSLyYcjYSGP5h9G9wQCxKbjWCH7wZUdxqPt71Bi00tlBXKHI9";

export enum LocationAuditActions {
  "DELOCATED" = 1,
  "RELOCATED" = 2,
}

export enum SenderLogos {
  "dsw.png" = "dsw",
  "gap.png" = "gap",
  "hsn.png" = "hsn",
  "qvc.png" = "qvc",
  "asos.png" = "asos",
  "ebay.png" = "ebay",
  "etsy.png" = "etsy",
  "nike.png" = "nike",
  "vici.png" = "vici",
  "chewy.png" = "chewy",
  "jcrew.png" = "jcrew",
  "kohls.png" = "kohls",
  "macys.png" = "macys",
  "amazon.png" = "amazon",
  "target.png" = "target",
  "zappos.png" = "zappos",
  "zulili.png" = "zulili",
  "barkbox.png" = "barkbox",
  "bestbuy.png" = "bestbuy",
  "express.png" = "express",
  "freshly.png" = "freshly",
  "groupon.png" = "groupon",
  "revolve.png" = "revolve",
  "sephora.png" = "sephora",
  "walmart.png" = "walmart",
  "wayfair.png" = "wayfair",
  "westelm.png" = "westelm",
  "madewell.png" = "madewell",
  "anntaylor.png" = "anntaylor",
  "blueapron.png" = "blueapron",
  "forever21.png" = "forever21",
  "nordstrom.png" = "nordstrom",
  "stitchfix.png" = "stitchfix",
  "hellofresh.png" = "hellofresh",
  "shutterfly.png" = "shutterfly",
  "fashionnova.png" = "fashionnova",
  "antropologie.png" = "antropologie",
  "neimanmarcus.png" = "neimanmarcus",
  "americaneagle.png" = "americaneagle",
  "anntaylorloft.png" = "anntaylorloft",
  "bedbathbeyond.png" = "bedbathbeyond",
  "victoriasecret.png" = "victoriasecret",
  "urbanoutfitters.png" = "urbanoutfitters",
  "bathandbodyworks.png" = "bathandbodyworks",
}

export enum PackageDeliveryPolicies {
  "LEAVE" = 1,
  "BUILDING_DO_NOT_LEAVE" = 3,
}

export const LoggingTimeout = 30;

export enum DeliveryVerificationMethods {
  "QR_CODE" = 1,
  "MANUAL_FALLBACK_CODE" = 2,
}

export enum WfmPublishSource {
  "AD_HOC" = 1,
  "TEMPLATE_WINDOW" = 2,
  "PUBLISH_ONCE" = 3,
}

export enum FetchOfferingTypes {
  "STANDARD_DELIVERY" = 1,
  "OVERSIZE_DELIVERY" = 2,
  "ASAP_DELIVERY" = 3,
}

export enum WfmConfigPublic {
  "CANCEL_BLOCK_ADMIN_PRIOR_IN_MINUTES" = 120,
  "OVERFILL_BLOCK_ADMIN_PRIOR_IN_MINUTES" = 120,
  "CANCEL_BLOCK_DRIVER_PRIOR_IN_MINUTES" = 120,
}

export enum Carriers {
  "AMAZON" = 1,
  "USPS" = 2,
  "FEDEX" = 3,
  "UPS" = 4,
  "DHL" = 5,
  "OTHER" = 6,
}

export enum WfmPayEntryState {
  "RECORDED" = 1,
  "VALIDATION_HOLD" = 2,
  "APPROVED" = 3,
  "POSTED" = 5,
  "REJECTED" = 6,
  "PAID" = 7,
}

export const ProofOfAttemptedDeliveryTimerMinutes = 10;

export enum PackageDeliveryPreferencesEnum {
  "LEAVE" = 1,
  "DO_NOT_LEAVE" = 2,
  "VERIFIED" = 5,
}
export function packageDeliveryPreferenceGetter(type?: number) {
  switch (type) {
    case 1:
      return "LEAVE";
    case 2:
      return "DO_NOT_LEAVE";
    case 5:
      return "VERIFIED";
    default:
      return "INVALID";
  }
}
export const PackageDeliveryPreferences = {
  LEAVE: "LEAVE",
  DO_NOT_LEAVE: "DO_NOT_LEAVE",
  VERIFIED: "VERIFIED",
  ASAP: "ASAP", // Temporary until backend lets us know what this will actually be called
} as const;

export enum IsoDayOfWeek {
  "MONDAY" = 1,
  "TUESDAY" = 2,
  "WEDNESDAY" = 3,
  "THURSDAY" = 4,
  "FRIDAY" = 5,
  "SATURDAY" = 6,
  "SUNDAY" = 7,
}

export enum DeliveryReceiptMethods {
  "SIGNATURE" = 1,
  "PHOTO" = 2,
  "PICKUP" = 4,
}

export enum DeliveryReceiptTypes {
  "RECEIPT" = 1,
  "ATTEMPT" = 2,
}

export const LeaveLiabilityWaiverUrl =
  "https://s3.amazonaws.com/fetchpackage-hermes-images-production/legal/liability_waiver_20181020.html";

export enum WfmPayEntrySources {
  "Schedule-Completed" = 1,
  "Schedule-Completed-Surged" = 2,
  "Schedule-Overfilled" = 3,
  "Adjustment-Bonus" = 4,
  "Adjustment-Extra" = 5,
  "Adjustment-Preferred" = 6,
  "Adjustment-Tier" = 7,
}

export const DirectUploadRegexValidation = "^[a-zA-Z0-9_-()./\\]+$";

export enum DriverClassifications {
  "DEDICATED_DRIVER" = 1,
  "DELIVERY_PARTNER" = 2,
}
