import React from "react";

import { authenticationActions } from "slices/authentication/authentication.actions";
import { BEM } from "helpers/BEM.helper";
import FetchModal from "views/common/modals/FetchModal";
import UserAttributeVerificationForm from "views/components/forms/UserAttributeVerificationForm";
import { useFetchSelector } from "helpers/hooks/useFetchSelector.hook";
import { useDispatch } from "react-redux";

const classes = new BEM({
  block: { name: "modals" },
  elements: [{ name: "verifyEmail" }],
  prefix: { name: "fetch" },
});

export const VerifyUserEmailFormModal: React.FC = () => {
  const authentication = useFetchSelector("authentication");
  const dispatch = useDispatch();

  return (
    <FetchModal
      title="Verify Email Address"
      className={classes.getElementClassNames("verifyEmail")}
      id="verify_email"
    >
      <div className="user-attribute-modal-text-padding">
        A verification code was sent to your email address. Please enter it
        below.
      </div>
      <UserAttributeVerificationForm
        onSubmit={(values) => {
          if (authentication.tokens && authentication.tokens.userToken) {
            dispatch(
              authenticationActions.verifyUserAttribute({
                attributeName: "email",
                code: values.code,
                userToken: authentication.tokens.userToken,
              }),
            );
          }
        }}
      />
    </FetchModal>
  );
};

export default VerifyUserEmailFormModal;
