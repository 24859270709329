import React from "react";

import { CardTitle, Col, Row } from "reactstrap";

import { uiActions } from "slices/ui/ui.actions";
import { userActions } from "slices/user/user.actions";
import { BEM } from "helpers/BEM.helper";
import { FetchIcon } from "views/common/misc/FetchIcon";
import FetchModal from "views/common/modals/FetchModal";
import ConfirmResetPasswordForm, {
  IConfirmResetPasswordFormValues,
} from "views/components/forms/ConfirmResetPasswordForm";
import { useDispatch } from "react-redux";

const classes = new BEM({
  block: { name: "ConfirmResetPasswordModal" },
  elements: [
    {
      extras: ["d-flex", "justify-content-center", "align-items-center"],
      name: "close",
    },
  ],
});

export const ConfirmResetPasswordModal: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <FetchModal
      className={classes.getBlockClassNames()}
      id="confirm_reset_password"
    >
      <Row>
        <Col>
          <CardTitle tag="h4">Confirm Password Reset</CardTitle>
        </Col>
        <Col
          xs="2"
          onClick={() => {
            dispatch(uiActions.clearModal());
          }}
          className={classes.getElementClassNames("close")}
        >
          <FetchIcon iconType="windowClose" />
        </Col>
      </Row>
      <ConfirmResetPasswordForm
        onSubmit={(values: IConfirmResetPasswordFormValues) => {
          if (values.temporaryPassword) {
            dispatch(uiActions.clearModal());
            dispatch(
              userActions.postResetPassword({
                temporaryPassword: values.temporaryPassword,
              }),
            );
          }
        }}
      />
    </FetchModal>
  );
};

export default ConfirmResetPasswordModal;
