import React from "react";

import { Button, Form } from "reactstrap";

import { BEM } from "helpers/BEM.helper";
import { Field, Formik } from "formik";
import { FetchInputFormik } from "views/common/forms/fields/FetchInputFormik";

export type UserAttributeVerificationFormProps = {
  onSubmit: (values: { code: string }) => void;
};
const classes = new BEM({
  block: { name: "attributeVerificationForm" },
  elements: [{ name: "code" }, { name: "submit" }],
  prefix: { name: "form" },
});

export const UserAttributeVerificationForm: React.FunctionComponent<
  UserAttributeVerificationFormProps
> = (props: UserAttributeVerificationFormProps) => {
  return (
    <Formik
      initialValues={{
        code: "",
      }}
      onSubmit={props.onSubmit}
      validate={(values) => {
        if (values.code.length < 1) {
          return {
            code: "Requires a valid code",
          };
        }
      }}
    >
      {(formProps) => {
        return (
          <Form onSubmit={formProps.handleSubmit} className="flex-column">
            <Field
              {...{
                className: classes.getElementClassNames("code"),
                component: FetchInputFormik,
                name: "code",
                type: "text",
              }}
            />
            <Button
              color="info"
              className={classes.getElementClassNames("submit")}
              type="submit"
            >
              Submit
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserAttributeVerificationForm;
